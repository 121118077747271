import React from 'react';
import './App.css';
import { useLocation } from "react-router-dom";

import { Link } from "react-router-dom";

const Success = props => {
     const params = useLocation().search;
//    if(params.indexOf('success') === -1){
//       return <></>;
//    }
    return (
        <div className="ReturnMessage">
            <div className='paymentField'>
                <h2>Your payment was accepted</h2>
            <h4>Our agents will contact you soon!</h4>
            </div>
            
            <a href='https://dkassociates.us'>
                <div className="link">Go back to Home page</div>
            </a>
        </div>
    );
};



export default Success;