
import React from 'react';
import './App.css';
import Payment from './payment';
import { BrowserRouter as Router } from "react-router-dom";
import Success from './Success';

function App(props) { 
  return (
    <Router>
      
      <div className="App">
      <header className="App-header">
        <h1>DK Associates - Payments</h1>
        <Success />
        {/* <Payment />                     */}
      </header>
    </div>
    </Router>
    
  );
}

export default App;
